/*Vela Sans*/
/*200*/
@font-face {
  font-family: 'Vela Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(VelaSans-ExtraLight.woff2) format('woff2');
}
/*300*/
@font-face {
  font-family: 'Vela Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(VelaSans-Light.woff2) format('woff2');
}
/*400*/
@font-face {
  font-family: 'Vela Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(VelaSans-Regular.woff2) format('woff2');
}
/*500*/
@font-face {
  font-family: 'Vela Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(VelaSans-Medium.woff2) format('woff2');
}
/*600*/
@font-face {
  font-family: 'Vela Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(VelaSans-SemiBold.woff2) format('woff2');
}
/*700*/
@font-face {
  font-family: 'Vela Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(VelaSans-Bold.woff2) format('woff2');
}
/*900*/
@font-face {
  font-family: 'Vela Sans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(VelaSans-ExtraBold.woff2) format('woff2');
}
